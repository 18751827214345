import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import JingniaoImg from "/static/cases/jingniao.png"
import ShouhangImg from "/static/cases/shouhang.png"
import WalmartImg from "/static/cases/walmart.png"
import GlassManufacturerImg from "/static/cases/glassManufacturer.jpg"
import { navigate } from "gatsby"

const cases = [
  {
    title: "装满满—智能装箱软件服务",
    desc: "智能装箱软件，一键计算最佳装箱配载规划方案，快速解决估柜、装箱、打包设计等难题",
    link: "jingniao",
    img: JingniaoImg,
  },
  {
    title: "码高高—混合码垛规划算法",
    desc: "蓝胖智汇混码算法基于自研时空优化算法引擎可根据订单中不同货品(SKU)信息，实时生成满足不同场景业务要求的稳定垛型",
    link: "shouhang",
    img: ShouhangImg,
  },
  {
    title: "可持续供应链-智能决策解决方案",
    desc: "蓝胖智汇供应链解决方案针对企业供应链流通环节的完整业务流程，基于时间空间多目前优化引擎与多维度大数据洞察",
    link: "walmart",
    img: WalmartImg,
  },
  {
    title: "某著名门窗企业智能小件打托装柜SaaS系统",
    desc: "蓝胖子与著名门窗外贸企业合作，快速为其交付一套智能门窗小件打托装柜SaaS系统，高效解决其货物产品出口的小件打托难题",
    link: "glassManufacturer",
    img: GlassManufacturerImg,
  },
]

const Cases = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const page = params.get("p") || 1
  return (
    <Layout>
      <Seo
        title="装箱管理软件_智能装箱码垛系统-蓝胖智汇Doraopt客户案例"
        keywords="装箱管理软件,智能装箱码垛系统"
        description="蓝胖智汇专注自研装箱管理软件、智能装箱码垛系统，为客户提供仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="./case-banner.png"
          alt="装箱管理软件_智能装箱码垛系统-蓝胖智汇Doraopt客户案例"
          className="w-full"
        />
        <h1 className="absolute top-28 left-24 text-white">成功案例</h1>
      </div>
      <div className="flex gap-6 max-w-screen-xl m-auto mt-24">
        {cases.map(p => (
          <div
            className="flex-1 group hover:cursor-pointer"
            key={p.title}
            onClick={() => navigate(p.link)}
          >
            <div className="relative h-0 pb-[65%]">
              <img
                src={p.img}
                alt={p.title}
                className="block w-full h-full absolute bg-cover bg-center bg-no-repeat"
              />
            </div>
            <div className="relative min-h-[220px] p-6 group-hover:bg-[#2d55a8] group-hover:text-white transition-all duration-300">
              <p className="text-xs text-gray-500 group-hover:text-white">
                客户成功案例
              </p>
              <h2 className="text-lg font-bold text-gray-800 group-hover:text-white">
                {p.title}
              </h2>
              <p className="mt-2 text-sm text-gray-500 group-hover:text-white">
                {p.desc}
              </p>
              <p className="absolute bottom-1 left-6 text-sm text-[#3055a4] group-hover:text-white">
                了解详情 {">"}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Cases
